import type { ComponentChildren, FunctionalComponent } from 'preact';

export interface BurgerCloseIconProps {
    children?: ComponentChildren;
}

export const BurgerCloseIcon: FunctionalComponent<BurgerCloseIconProps> = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.70696 20.6775C3.31643 20.287 3.31643 19.6538 3.70696 19.2633L19.2633 3.70696C19.6538 3.31643 20.287 3.31643 20.6775 3.70696C21.068 4.09748 21.068 4.73064 20.6775 5.12117L5.12117 20.6775C4.73065 21.068 4.09748 21.068 3.70696 20.6775Z"
            fill="#1A1A1A"
        />
        <path
            d="M3.70876 3.70877C4.09928 3.31824 4.73245 3.31824 5.12297 3.70877L20.6793 19.2651C21.0698 19.6556 21.0698 20.2888 20.6793 20.6793C20.2888 21.0699 19.6556 21.0699 19.2651 20.6793L3.70876 5.12298C3.31823 4.73246 3.31823 4.09929 3.70876 3.70877Z"
            fill="#1A1A1A"
        />
    </svg>
);
