import type { FunctionalComponent } from 'preact';

import { cnSidebar } from '../sidebar.constants.js';

import './sidebar__logo.css';

export interface SidebarLogoProps {}

export const SidebarLogo: FunctionalComponent<SidebarLogoProps> = () => (
    <a href="/" className={cnSidebar('logo')}>
        <svg width="99" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.956 8.359c-1.93 0-2.957.708-2.957 2.762v6.598H9.965V4.571h3.885v2.493c.611-1.539 1.712-2.517 3.593-2.59.196 0 .415 0 .61.023v3.936a9.288 9.288 0 0 0-1.1-.074M19.644 17.721h4.058V4.571h-4.058v13.15zm0-14.494h4.033V0h-4.033v3.227zM32.336 14.91c1.612 0 2.932-1.32 2.932-3.764s-1.32-3.74-2.932-3.74c-1.613 0-2.91 1.027-2.91 3.74s1.32 3.763 2.91 3.763zm1.32-10.633c3.372 0 5.793 2.566 5.793 6.869 0 4.302-2.42 6.893-5.794 6.893-1.442 0-3.177-.636-4.033-2.077V22H25.59V4.57h4.032v1.784c.856-1.442 2.591-2.077 4.033-2.077zM44.727 0h-4.032v17.721h4.032V0zM55.657 9.727c-.049-1.638-1.246-2.468-2.762-2.468-1.417 0-2.713.733-2.932 2.468h5.694zm-2.59 8.31c-4.278 0-7.211-2.615-7.211-6.965s3.202-6.892 7.04-6.892c4.35 0 7.038 3.325 6.77 7.992h-9.752c.122 1.858 1.564 2.739 3.031 2.739 1.468 0 2.493-.538 2.958-1.32h3.715c-1.173 3.103-3.91 4.448-6.55 4.448M79.934 9.727c-.048-1.638-1.246-2.468-2.761-2.468-1.417 0-2.713.733-2.933 2.468h5.695zm-2.59 8.31c-4.278 0-7.21-2.615-7.21-6.965s3.202-6.892 7.039-6.892c4.35 0 7.039 3.325 6.77 7.992H74.19c.122 1.858 1.564 2.739 3.031 2.739s2.492-.538 2.958-1.32h3.715c-1.174 3.103-3.91 4.448-6.55 4.448M93.5 4.277c3.374 0 4.424 1.98 4.424 5.549v7.895H93.89v-6.892c0-1.882-.074-3.421-2.029-3.421-1.71 0-2.443 1.148-2.443 3.568v6.747h-4.033V4.57h4.033v1.735c.586-.978 2.028-2.029 4.081-2.029zM65.146 14.339l-.005-6.91h4.016V4.571h-4.016V.978h-4.033v9.51c0 1.645.116 3.85 3.273 3.85 0 .573.643 3.643 5.232 3.364V14.34h-4.467zM4.038 14.339l-.005-6.91h4.016V4.571H4.033V.978H0v9.51c0 1.645.116 3.85 3.273 3.85 0 .573.642 3.643 5.232 3.364V14.34H4.038z"
                fill="#1A1A1A"
            />
        </svg>
    </a>
);
