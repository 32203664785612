import type { FunctionalComponent } from 'preact';

export interface BurgerIconProps {}

export const BurgerIcon: FunctionalComponent<BurgerIconProps> = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect y="4" width="24" height="2" rx="1" fill="#1A1A1A" />
        <rect y="11" width="24" height="2" rx="1" fill="#1A1A1A" />
        <rect y="18" width="24" height="2" rx="1" fill="#1A1A1A" />
    </svg>
);
