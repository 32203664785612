import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnSidebar } from '../sidebar.constants.js';

import './sidebar__login.css';

export interface SidebarLoginProps {
    href?: string;
    children: ComponentChildren;
}

export const SidebarLogin: FunctionalComponent<SidebarLoginProps> = ({ href, children }) => (
    <a className={cnSidebar('login')} href={href}>
        {children}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none">
            <rect
                x="1"
                y="1"
                width="7"
                height="4"
                transform="matrix(4.37114e-08 1 1 -4.37114e-08 4.37114e-08 -4.37114e-08)"
                stroke="#1A1A1A"
                strokeWidth="2"
            />
            <rect width="2" height="10" transform="matrix(4.37114e-08 1 1 -4.37114e-08 6 3)" fill="#1A1A1A" />
            <rect width="3" height="2" transform="matrix(4.37114e-08 1 1 -4.37114e-08 13 5)" fill="#1A1A1A" />
            <rect width="3" height="2" transform="matrix(4.37114e-08 1 1 -4.37114e-08 9 5)" fill="#1A1A1A" />
        </svg>
    </a>
);
